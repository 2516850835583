.deadlines {
  &-item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    &:nth-child(odd) {
      background: #ebebeb65;
    }

    h5 {
      font-size: 1.15rem;
    }

    @include mobile {
      padding: 6px 4px;
      flex-direction: row;
    }

    @include mobile {
      h5 {
        font-size: 1rem;
      }
    }
    .deadlines-items-date-and-title {
      width: 65%;
      display: flex;
      @include mobile {
        width: 65%;
        flex-direction: column;
      }
    }

    &-date {
      width: 62px;
    }

    &-details {
      @include mobile {
        margin-top: 14px;
      }
    }

    &-links {
      width: 20%;
      position: relative;
      display: flex;
      justify-content: space-between;
      // height: 60px;
      @include mobile {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

.cta-color {
  color: #28a745 !important;
  border-color: #28a745;
  &:hover {
    border-color: #28a745;
    background: #28a745;
    color: white !important;
  }
}

.cta-color-filled {
  border-color: #28a745;
  background: #28a745;
  color: white !important;
  &:hover {
    color: white;
  }
}

.blue-color {
  color: blue;
  border-color: blue;
  &:hover {
    border-color: blue;
    background: blue;
    color: white;
  }
}

.smaller {
  font-size: 12px;
  padding: 2px 4px;
  transition: opacity 400ms;
  background: transparent;
}

.medium {
  font-size: 14px;
  padding: 4px 8px;
  transition: opacity 400ms;
}

.released-color {
  background: rgba(236, 88, 93, 0.5);
}

.remaining-color {
  background: rgba(236, 88, 93, 1);
}

.tickets {
  &-number {
    font-size: 1.75rem;
    margin-right: 0.5rem;
    line-height: 0.8;
    width: 80px;
  }
  &-type {
    font-size: 1rem;
    opacity: 0.65;
    line-height: 1.9;
  }
  &-row {
    margin-bottom: 1rem;
    display: flex;
    padding: 0 10px;
  }
  &-color-box {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
}

.grey-bg {
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 0;
  ul {
    list-style: none;
    margin-bottom: 0;
    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding-bottom: 6px;
      margin-bottom: 6px;
      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
