@import "~bootstrap/scss/bootstrap.scss";
@import "~@wordpress/components/build-style/style.css";
@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap");
@import "variables";
@import "login";
@import "main";
@import "dashboard";
@import "showroom";

html,
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  height: 100%;
}

a:hover {
  color: $tech-orange;
  opacity: 0.7;
  text-decoration: none;
}

body {
  background: #f4f5fa;
  color: #212121;
  font-family: "Work Sans", sans-serif;
}

h1 {
  font-size: 1.5rem;
  color: $tech-orange;
}

h2 {
  font-size: 1.25rem;
  font-weight: 400;
}

h3 {
  color: $tech-orange;
}
