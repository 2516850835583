@import "../../styles/variables";

.card {
  list-style: none;
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 1rem;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  p {
    font-size: 1rem;
  }
}

#grip {
  h1 {
    font-weight: bold;
  }
}

.grip-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.grip-card {
  width: 444px;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
  &-description {
    max-width: 600px;
  }
  @include mobile {
    margin-right: 0;
    width: 100%;
    padding: 20px;

    iframe {
      margin: 0 auto;
    }
  }
}
