@import "../../styles/variables";

.addons {
  color: white;
  ul {
    list-style: none;
  }
}

.addons-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.addon-card {
  height: 100%;
  width: 320px;
  text-align: center;
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &-header {
    border-radius: 1rem 1rem 0 0;
    padding: 1.5rem 0;
    background: $gradient-bg;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 1rem;
    color: black;

    h5 {
      font-size: 1.5rem;
      margin: 1rem 0 2rem;
      font-weight: 700;
    }
  }

  h4 {
    font-size: 2.125rem;
  }

  span {
    font-size: 0.875rem;
    opacity: 0.65;
  }

  ul {
    display: flex;
    flex-direction: column;
    color: black;
    padding: 1rem;
    background-color: white;
    text-align: left;
    border-radius: 0 0 1rem 1rem;

    li {
      margin-bottom: 0.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      margin-bottom: 1rem;
    }
  }
}
