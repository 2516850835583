@import "variables";

.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gradient-bg;

  form {
    color: white;
    max-width: 320px;
    text-align: center;
    h1 {
      color: rgba(255, 255, 255, 0.4);
      margin-bottom: 2rem;
      font-size: 1rem;
    }
    label {
      font-size: 2rem;
    }
    input {
      color: white;
      font-weight: bold;
      border: none;
      background-color: rgba(255, 255, 255, 0.2);
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
      outline-color: white;
      &::placeholder {
        font-weight: normal;
        color: rgba(255, 255, 255, 0.5);
      }
    }
    button {
      color: $tech-orange;
      border-radius: 2rem;
      font-size: 1.5rem;
      background-color: white;
      padding: 0.5rem 1.25rem;
    }
  }
}
