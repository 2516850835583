@import "../../styles/variables";

nav {
  position: fixed;
  background: #fefefe;
  width: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
  // min-height: 100vh;
  padding: 2rem 0 2rem 2rem;
  border-right: 3px solid #ededf2;

  li {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    margin-top: 40px;
    list-style: none;
    margin-right: -3px;
    margin-bottom: 20px;
  }

  a {
    color: #bcc1d8;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.active {
      color: #212121;
      border-right: 3px solid $tech-orange;

      svg {
        color: $tech-orange;
      }
    }
  }

  h2 {
    color: #bcc1d8;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 1rem;
  }

  .log-out {
    background-color: transparent;
    border: none;
    color: $tech-orange;
    text-decoration: underline;
  }
}

.hamburger-menu {
  display: none;
  @include mobile {
    display: block;
    position: fixed;
    z-index: 3;
    top: 2rem;
    right: 10px;
    background-color: $tech-orange;
    color: white;
    font-weight: bold;
    border: none;
    padding: 10px;
    border-radius: 4px;
    outline: none !important;
  }
}

@include mobile {
  .overlay {
    display: block;
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    pointer-events: none;
    transition: opacity 350ms ease-in-out;
    opacity: 0;

    &-show {
      opacity: 1;
      pointer-events: visible;
    }
  }
  nav {
    width: 280px;
    transition: transform 350ms ease-in-out;
    transform: translateX(-100%);
    z-index: 2;
    a {
      font-size: 1.5rem;
    }
    ul {
      margin-top: 125px;
    }
  }
  &.nav-show {
    transform: translateX(0%);
  }
  .log-out {
    font-size: 1.25rem;
  }
}
