@import "variables";

#showroom .card {
  padding: 22px;
}

.booth-card {
  font-size: 1.5rem;
  padding: 0 22px;
  justify-content: space-between;

  @include mobile {
    font-size: 1rem;
  }
}
