// Colors
$tech-orange: #ff5d3c;

// Background
$gradient-bg: linear-gradient(
  45deg,
  rgba(230, 122, 83, 1) 0%,
  rgba(220, 68, 87, 1) 88%
);

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin tablet-only {
  @media (max-width: 1023px) and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: 1280px) {
    @content;
  }
}
