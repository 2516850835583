@import "variables";

main {
  padding: 30px 50px 30px 300px;
  width: 100%;

  @include mobile {
    padding: 20px;
  }

  h1 {
    color: #212121;
    font-weight: 400;
  }

  hr {
    margin: 22px 0 34px;
    border: 1px solid #ededf2;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    text-align: center;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.45;
    color: #555555;
  }

  .main-header {
    display: flex;
  }

  .partner-logo {
    // width: 200px;
    // height: 100px;
    margin: 0 1rem 1rem 0;

    img {
      object-fit: contain;
      width: auto;
      height: auto;
      max-width: 200px;
      max-height: 100px;
    }
  }
}
